.scroll-down-wrapper {
  position: relative;
  border-radius: 8px;
  width: 48px;
  height: 48px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: transparent;
    transition: all 0.3s ease;
    border-radius: 8px;
  }

  &::before {
    left: 0;
    bottom: 0;
    width: 1px;
    height: 0;
    box-shadow: calc(100% - 1px) 0 0 0 transparent;
  }

  &::after {
    right: 0;
    bottom: 0;
    width: 1px;
    height: 0;
    box-shadow: -100% 0 0 0 transparent;
  }

  &:hover {
    &::before,
    &::after {
      height: 100%;
      background-color: #ffffff;
    }

    &::before {
      transition-delay: 0s;
      box-shadow: calc(100% - 2px) 0 0 0 #ffffff;
    }

    &::after {
      transition-delay: 0s;
      box-shadow: -100% 100% 0 0 #ffffff;
    }
  }
}
