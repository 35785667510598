@tailwind base;
@tailwind components;
@tailwind utilities;

.mapboxgl-popup-content {
  background: black !important;
  font-size: 24px;
  border-radius: 0px !important;
  padding: 10px !important;
}

.mapboxgl-popup-tip {
  border-top-color: black !important;
}

.mapboxgl-popup-close-button {
  color: white !important;
  top: 1px !important;
  right: 3px !important;
}
