@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Pencerio";
    src:
      url("../../assets/fonts/pencerio/Pencerio-Hairline.woff2") format("woff2"),
      url("../../assets/fonts/pencerio/Pencerio-Hairline.woff") format("woff"),
      url("../../assets/fonts/pencerio/Pencerio-Hairline.ttf")
        format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  //   @font-face {
  //     font-family: "Pencerio";
  //     src:
  //       url("../../assets/fonts/pencerio/Pencerio-Light.woff2") format("woff2"),
  //       url("../../assets/fonts/pencerio/Pencerio-Light.woff") format("woff"),
  //       url("../../assets/fonts/pencerio/Pencerio-Light.ttf") format("truetype");
  //     font-weight: 300;
  //     font-style: normal;
  //     font-display: swap;
  //   }
}
