@tailwind base;
@tailwind components;
@tailwind utilities;

// Ce fichier permet de gérer les styles globaux

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  color: rgb(var(--foreground-rgb));
  font-size: 16px;
  font-weight: 300;
  min-height: 100%;
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
  background-size: cover;
}

header {
  max-width: 100vw !important;
  height: 64px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-nav-bar {
  height: calc(100dvh - 64px) !important;
}

.monthly-event-subtitle {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #ffffff;
}

// After your existing code in globals.scss

// .font-poppins {
//   font-family: "Poppins", sans-serif;
// }

// .font-pencerio {
//   font-family: "Pencerio-Hairline", serif; // Using Hairline as it's the thinnest version
// }

// .font-pencerio-thin {
//   font-family: "Pencerio-Hairline", serif;
//   font-style: italic;
//   font-weight: 10;
// }

// .font-pencerio-extra-thin {
//   font-family: "Pencerio-Hairline", serif;
//   font-style: italic;
//   font-weight: 50;
//   -webkit-font-smoothing: antialiased;
//   font-synthesis: none;
// }

// .font-cormorant {
//   font-family: "Cormorant Garamond", serif;
// }
